import "./src/css/tailwind.css"
import "./src/css/index.css"
import * as Sentry from "@sentry/gatsby";
import { isInWxworkBrowser } from '@/libs/utils';

export const onClientEntry = () => {
    console.log("onClientEntry")

    if (isInWxworkBrowser()) {
        Sentry.setTag("wxwork", "true")
        console.log("Sentry set tag for wxwork browser")
    }
}

export { wrapRootElement, wrapPageElement } from './gatsby-ssr';
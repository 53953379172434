import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useTranslation } from 'react-i18next'
import { GatsbyImage } from "gatsby-plugin-image";

const SiteLogo = () => {
  const { i18n } = useTranslation()

  const defaultLogoName = 'chunhuizk-textlogo-cn'

  const logoName = (i18n.language === 'zh-CN' || i18n.language === 'zh-HK') ? defaultLogoName : 'chunhuizk-textlogo-en'

  const allSiteLogos = useStaticQuery(graphql`query SiteLogoImageQuery {
  logos: allFile(filter: {relativePath: {regex: "/site-logos/"}}) {
    edges {
      node {
        name
        childImageSharp {
          gatsbyImageData(width: 200, layout: CONSTRAINED)
        }
      }
    }
  }
}
`)

  // const logoNames = ['chunhuizk-textlogo-cn', 'chunhuizk-textlogo-en', 'chunhuizk-iconlogo', 'chunhuizk-textlogo-cn-20-anniversary']
  const displayLogoImage = allSiteLogos.logos.edges.find(({ node: { name } }) => name === logoName)

  return (
      <GatsbyImage
        alt="Chunhui Controls Logo"
        image={displayLogoImage.node.childImageSharp.gatsbyImageData}
        style={{ height: "100%" }}
        imgStyle={{ objectFit: "contain", objectPosition: "left" }} />
  );
}

export default SiteLogo
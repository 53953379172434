exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-leadership-executives-tsx": () => import("./../../../src/pages/about/leadership/executives.tsx" /* webpackChunkName: "component---src-pages-about-leadership-executives-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-contact-anonymous-report-tsx": () => import("./../../../src/pages/contact/anonymous-report.tsx" /* webpackChunkName: "component---src-pages-contact-anonymous-report-tsx" */),
  "component---src-pages-contact-integrity-inquiry-tsx": () => import("./../../../src/pages/contact/integrity-inquiry.tsx" /* webpackChunkName: "component---src-pages-contact-integrity-inquiry-tsx" */),
  "component---src-pages-contact-request-tsx": () => import("./../../../src/pages/contact/request.tsx" /* webpackChunkName: "component---src-pages-contact-request-tsx" */),
  "component---src-pages-employee-care-tsx": () => import("./../../../src/pages/employee-care.tsx" /* webpackChunkName: "component---src-pages-employee-care-tsx" */),
  "component---src-pages-environmental-protection-tsx": () => import("./../../../src/pages/environmental-protection.tsx" /* webpackChunkName: "component---src-pages-environmental-protection-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innovation-tsx": () => import("./../../../src/pages/innovation.tsx" /* webpackChunkName: "component---src-pages-innovation-tsx" */),
  "component---src-pages-investers-tsx": () => import("./../../../src/pages/investers.tsx" /* webpackChunkName: "component---src-pages-investers-tsx" */),
  "component---src-pages-jobs-[id]-tsx": () => import("./../../../src/pages/jobs/[id].tsx" /* webpackChunkName: "component---src-pages-jobs-[id]-tsx" */),
  "component---src-pages-jobs-search-tsx": () => import("./../../../src/pages/jobs/search.tsx" /* webpackChunkName: "component---src-pages-jobs-search-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-manuals-product-id-tsx": () => import("./../../../src/pages/manuals/{Product.id}.tsx" /* webpackChunkName: "component---src-pages-manuals-product-id-tsx" */),
  "component---src-pages-ppt-tsx": () => import("./../../../src/pages/ppt.tsx" /* webpackChunkName: "component---src-pages-ppt-tsx" */),
  "component---src-pages-products-[id]-tsx": () => import("./../../../src/pages/products/[id].tsx" /* webpackChunkName: "component---src-pages-products-[id]-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-product-category-id-tsx": () => import("./../../../src/pages/products/{Product.categoryId}.tsx" /* webpackChunkName: "component---src-pages-products-product-category-id-tsx" */),
  "component---src-pages-products-product-department-path-tsx": () => import("./../../../src/pages/products/{Product.departmentPath}.tsx" /* webpackChunkName: "component---src-pages-products-product-department-path-tsx" */),
  "component---src-pages-products-product-id-tsx": () => import("./../../../src/pages/products/{Product.id}.tsx" /* webpackChunkName: "component---src-pages-products-product-id-tsx" */),
  "component---src-pages-products-tag-tag-id-tsx": () => import("./../../../src/pages/products/tag/{Tag.id}.tsx" /* webpackChunkName: "component---src-pages-products-tag-tag-id-tsx" */),
  "component---src-pages-sectors-auto-tsx": () => import("./../../../src/pages/sectors/auto.tsx" /* webpackChunkName: "component---src-pages-sectors-auto-tsx" */),
  "component---src-pages-sectors-energy-tsx": () => import("./../../../src/pages/sectors/energy.tsx" /* webpackChunkName: "component---src-pages-sectors-energy-tsx" */),
  "component---src-pages-sectors-heating-tsx": () => import("./../../../src/pages/sectors/heating.tsx" /* webpackChunkName: "component---src-pages-sectors-heating-tsx" */)
}


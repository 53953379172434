import React, { useEffect } from 'react';
import i18n from './src/i18n/config';
import { I18nextProvider } from 'react-i18next';
import Header from './src/components/Header'
import Footer from './src/components/Footer'
import { Script } from "gatsby"
import { Toaster } from "@/components/ui/toaster"


export const wrapRootElement = ({ element }) => {
    return (
        <>
            <Script defer data-domain="chunhuizk.com" src="https://analytics.granddongshan.com/js/plausible.js"></Script>

            <I18nextProvider i18n={i18n}>
                {element}
            </I18nextProvider>

        </>
    )
};

export const wrapPageElement = ({ element, props }) => {
    const { pageContext, location } = props
    const { locale } = pageContext
    const { pathname } = location

    useEffect(() => {
        if (locale) {
            i18n.changeLanguage(locale)
        }
    }, [locale])

    const hideHeaderAndFooter = pathname === "/login/" || pathname.indexOf("/manuals/") >= 0

    if (hideHeaderAndFooter) {
        return (
            <>
                {element}
            </>
        )
    }

    return (
        <>
            <Header pageContext={pageContext} />
            {element}
            <Toaster />
            <Footer pageContext={pageContext} />
        </>
    )
};
import * as Sentry from "@sentry/gatsby";

Sentry.init({
    dsn: "https://eb53b2ddd53fb04374d7d730f6ff656a@sentry.chunhuicloud.com/4506046991892480",
    integrations: [],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});